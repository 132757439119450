<template>
<section>
  <loading/>
  <div class="dosing">
    <div class="banner">
      <div class="banner-image">
        <img src="~@/assets/images/unsplash_2.png" alt="Dosing Pumps Wiki" />
      </div>
      <div class="overlay"></div>
      <div class="flexbox">
        <h1 id="banner-titl" class="text-center">Dosing Pumps Wiki</h1>
      </div>
    </div>

    <div class="container main-content">
      <div class="main">
        <transition name="fade">
        <router-view></router-view>
        </transition>
      </div>
      <div class="side-bar">
        <div class="dosing-sidebar">
          <div class="sidebar-header">
            <img
              src="~@/assets/images/dosing-sidebar-top-image.png"
              alt="dosing-sidebar-top-image"
            />
            <h2 class="sidebar-title">PUMPS SUPPORT</h2>
          </div>
          <ul>
            <li>
              <router-link tag="a" active-class="active" to="/dosing-pumps-support/installation"
                >Installation &#38; Operation</router-link
              >
            </li>
            <li>
              <router-link tag="a" active-class="active" to="/dosing-pumps-support/wiki" @click="changetitle()"
                >Dosing Pump Wiki</router-link
              >
            </li>
          </ul>
        </div>

        <div class="online-consult">
          <img
            src="~@/assets/images/consult-icon.png"
            alt="Online Consultation"
          />
          <p>Online Consultation</p>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import Loading from '../components/loading.vue';

export default {
  name: "PumpSupport",
  components: {
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.image-card {
  margin: 2em 0;
  img {
    max-width: 100%;
    max-height: 250px;
    //  object-fit: cover;
  }
}
</style>