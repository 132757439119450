import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Video from "../views/Video.vue";
import SuccessSubmission from "../views/SuccessSubmission.vue";
import DosingPumps from "../views/DosingPumps.vue";
import DosingPackages from "../views/DosingPackages.vue";
import Solution from "../views/Solution.vue";
import PumpSupport from "../views/PumpSupport.vue";
import Application from "../views/Application.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
  },
  {
    path: "/success-submission",
    name: "SuccessSubmission",
    component: SuccessSubmission,
  },
  {
    path: "/dosing-pumps",
    component: DosingPumps,
    children: [
      {
        path: "",
        component: () =>
          import("../components/pumps-components/TabDefault.vue"),
      },
      {
        path: "high-pressure-dosing-pump",
        component: () =>
          import("../components/pumps-components/HighPressure.vue"),
      },
      {
        path: "manual-dosing-pump",
        component: () =>
          import("../components/pumps-components/ManualDosing.vue"),
      },
      {
        path: "metering-pump",
        component: () =>
          import("../components/pumps-components/TabMetering.vue"),
      },
      {
        path: "solenoid-pump",
        component: () =>
          import("../components/pumps-components/TabSolenoid.vue"),
      },
      {
        path: "diaphragm-pump",
        component: () =>
          import("../components/pumps-components/TabDiaphragm.vue"),
      },
      {
        path: "motor-driven-diaphragm-pump",
        component: () => import("../components/pumps-components/TabMotor.vue"),
      },
      {
        path: "low-pressure-dosing-pump",
        component: () =>
          import("../components/pumps-components/LowPressure.vue"),
      },
    ],
  },
  {
    path: "/dosing-packages",
    name: "DosingPackages",
    component: DosingPackages,
  },
  {
    path: "/dosing-application",
    component: Application,
    children: [
      {
        path: "",
        component: () =>
          import("../components/application-components/Default.vue"),
      },
      {
        path: "chemical-processing",
        component: () =>
          import("../components/application-components/ChemicalProcessing.vue"),
      },
      {
        path: "integrated-dosing-system",
        component: () =>
          import(
            "../components/application-components/IntegratedDosingSystem.vue"
          ),
      },
      {
        path: "pulp-and-paper-pump",
        component: () =>
          import("../components/application-components/PulpPaperPump.vue"),
      },
      {
        path: "surface-treatment",
        component: () =>
          import("../components/application-components/SurfaceTreatment.vue"),
      },
      {
        path: "waste-water-treatment-pump",
        component: () =>
          import(
            "../components/application-components/WasteWaterTreatmentPump.vue"
          ),
      },
    ],
  },
  {
    path: "/dosing-pumps-support",
    component: PumpSupport,
    children: [
      {
        path: "installation",
        component: () =>
          import("../components/support-components/TabInstallation.vue"),
      },
      {
        path: "wiki",
        component: () => import("../components/support-components/TabWiki.vue"),
      },
    ],
  },
  {
    path: "/solution",
    component: Solution,
    children: [
      {
        path: "",
        component: () =>
          import("../components/solution-components/TabDefault.vue"),
      },
      {
        path: "chlorine",
        component: () =>
          import("../components/solution-components/TabChlorine.vue"),
      },
      {
        path: "desalination",
        component: () =>
          import("../components/solution-components/TabDesalination.vue"),
      },
      {
        path: "epoxy",
        component: () =>
          import("../components/solution-components/TabEpoxy.vue"),
      },
      {
        path: "saltwater",
        component: () =>
          import("../components/solution-components/TabSaltwater.vue"),
      },
      {
        path: "sulfuric",
        component: () =>
          import("../components/solution-components/TabSulfuric.vue"),
      },
      {
        path: "microchemical",
        component: () =>
          import("../components/solution-components/TabMicrochemical.vue"),
      },
    ],
  },
  {
    path: "/company",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Company.vue"),
    children: [
      {
        path: "profile",
        component: () =>
          import("../components/company-components/CompanyProfile.vue"),
      },
      {
        path: "culture",
        component: () =>
          import("../components/company-components/CompanyCulture.vue"),
      },
      {
        path: "our-history",
        component: () =>
          import("../components/company-components/OurHistory.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
