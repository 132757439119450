<template>
  <section>
    <loading />
    <div class="application">
      <div class="banner">
        <div class="banner-image">
          <img
            src="~@/assets/images/dosing-top.png"
            alt="Diaphragm Dosing Metering Pump Solutions"
          />
        </div>
        <div class="overlay"></div>
        <div class="flexbox">
          <h1 id="banner-title" class="text-center">Submission Successful</h1>
          <!-- <h1 class="text-center"></h1> -->
        </div>
      </div>

      <div class="container main-content">
        <div class="main">
          <div class="richtext">
            <p>
              Thanks for your inquiry! Newdose can offer all kinds of diaphragm
              dosing metering pumps&nbsp;and we are glad to resolve nonstandard
              and unique tasks for you.&nbsp;Contact us to learn more details
              about our&nbsp;diaphragm dosing metering pumps.&nbsp;We will
              respond within 24 hours.
            </p>
            <div class="center">
              <router-link to="/">Go back Home</router-link>
            </div>
          </div>
        </div>
        <div class="side-bar">
          <div class="online-consult">
            <img
              src="~@/assets/images/consult-icon.png"
              alt="Online Consultation"
            />
            <p>Online Consultation</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import loading from "../components/loading.vue";
export default {
  components: { loading },
  title: "Submission Sucessful",
};
</script>

<style lang="scss" scoped>
.center {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    a {
      margin: 0.5em auto;
      color: #fff;
      padding: 8px 50px;
      background: #065695;
      border: 2px solid #065695;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #065695;
        background: #fff;
      }
    }
</style>
