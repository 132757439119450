<template>
  <footer>
    <div class="footer-content">
      <div class="col">
        <ul>
          <li class="title"><router-link to="/dosing-pumps">DOSING PUMPS</router-link></li>
          <li>
            <router-link to="/dosing-pumps/solenoid-pump"
              >Selenoids Dosing Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/motor-driven-diaphragm-pump"
              >Motor Driven Diaphragm Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/metering-pump"
              >Metering Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/diaphragm-pump"
              >Diaphragm Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/low-pressure-dosing-pump"
              >Low Pressure Dosing Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/high-pressure-dosing-pump"
              >High Pressure Dosing Pump</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps/manual-dosing-pump"
              >Manual Dosing Pump</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <ul>
          <li class="title"><router-link to="/dosing-application">APPLICATION</router-link></li>
          <li>
            <router-link to="/dosing-application/waste-water-treatment-pump"
              >Waste Water Treatment</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-application/integrated-dosing-system"
              >Integrated Dosing System</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-application/chemical-processing"
              >Chemical Processing</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-application/pulp-and-paper-pump"
              >Pulp &amp; Paper</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-application/surface-treatment"
              >Surface Treatment</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <ul>
          <li class="title"><router-link to="/dosing-pumps-support">PUMPS SUPPORT</router-link></li>
          <li>
            <router-link to="/dosing-pumps-support/installation"
              >Installation and Operation</router-link
            >
          </li>
          <li>
            <router-link to="/dosing-pumps-support/wiki"
              >Dosing Pumps Wikki</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col">
        <ul>
          <li class="title"><router-link to="/company/profile">COMPANY</router-link></li>
          <li>
            <router-link to="/company/profile">Company Profile</router-link>
          </li>
          <li>
            <router-link to="/company/culture">Company Culture</router-link>
          </li>
          <li>
            <router-link to="/company/our-history">Our History</router-link>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul>
          <li class="title"><router-link to="/contact">CONTACT US</router-link></li>
          <li><router-link to="/">watergateflow.co.za</router-link></li>
          <li>projects@watergateflow.co.za</li>
          <li>info@watergateflow.co.za</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    // msg: String
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer {
  margin: 0;
  background: black;
  color: white;
  padding: 2em;
  line-height: 2em;
  font-size: 0.9em;

  .footer-content {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
      padding: 0;
    }

    .col {
      min-width: 150px;

      ul {
        li {
          &.title {
            margin-bottom: 2em;
            font-weight: bold;
            font-size: 1.2em;

            a {
              &:hover, &:active {
                color: #065695;
              }
            }
          }
          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
