<template>
  <section>
    <loading/>
    <div class="application">
      <div class="banner">
        <div class="banner-image">
          <img
            src="~@/assets/images/unsplash_2.png"
            alt="DIAPHRAGM DOSING METERING PUMP APPLICATION"
          />
        </div>
        <div class="overlay"></div>
        <div class="flexbox">
          <h1 id="banner-title" class="text-center">
            DIAPHRAGM DOSING METERING PUMP APPLICATION
          </h1>
        </div>
      </div>

      <div class="container main-content">
        <div class="main">
          <transition name="fade">
            <transition name="fade">
          <router-view></router-view>
            </transition>
          </transition>
        </div>
        <div class="side-bar">
          <div class="dosing-sidebar">
            <div class="sidebar-header">
              <h2 class="sidebar-title">APPLICATIONS</h2>
            </div>
            <ul>
              <li><router-link active-class="active" to="/dosing-application/waste-water-treatment-pump">Waste Water Treatment</router-link></li>
              <li><router-link active-class="active" to="/dosing-application/integrated-dosing-system">Integrated Dosing System</router-link></li>
              <li><router-link active-class="active" to="/dosing-application/chemical-processing">Chemical Processing</router-link></li>
              <li><router-link active-class="active" to="/dosing-application/pulp-and-paper-pump">Pulp &amp; Paper</router-link></li>
              <li><router-link active-class="active" to="/dosing-application/surface-treatment">Surface Treatment</router-link></li>
            </ul>
          </div>
          <div class="online-consult">
            <img
              src="~@/assets/images/consult-icon.png"
              alt="Online Consultation"
            />
            <p>Online Consultation</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '../components/loading.vue';

export default {
  name: "Application",
  components: {
    Loading
  }
};
</script>

<style lang="scss" scoped>
$color1: #065695;
$color2: #49b9e7;
$color3: #1d1b1b;

.app-card {
  margin: 12px 0;
  display: flex;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .card-image {
    // height: 18rem;
    flex: 1 0 0;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .card-texts {
    flex: 1 0 0;
    padding: 0.5em;
    h3 {
      font-weight: 300;
      display: inline-block;
      padding-bottom: 5px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        bottom: 0;
        border-bottom: 3px solid #065695;
      }
    }
  }
}
</style>