<template>
  <div id="app">
    <app-header />
    <preloader />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
import preloader from "./components/loading.vue";
// import loader from "vue-ui-preloader";
// import PreLoader1 from './components/PreLoader.vue';

export default {
  name: "Home",
  data() {
    return {
      // isloaded: false,
    };
  },
  components: {
    AppHeader,
    AppFooter,
    preloader,
    // loader,
  },

  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(function () {
          // v.message = "Hi Bro, SetTimeout is working fine.";
          // this.isloaded = true;
        }, 30000);
      }
    };
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: Lato;
  src: url("~@/assets/fonts/Lato-Regular.ttf");
}

.container:after,
.container:before,
.nav-content:after,
.nav-content:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

#app {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // font-size: 14px;
}

$color1: #363e44;
$color2: #49b9e7;
$color3: #f1f1f1;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  font-size: 16px;
}

ul,
li,
a,
button,
input {
  text-decoration: none;
  outline: none;
  list-style: none;
}
.side-bar {
  width: 30%;
  padding: 1em;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.container {
  margin: 1em 3rem;

  @media (max-width: 800px) {
    margin: 1em 24px;
  }
}

.dosing-sidebar {
  // margin: 1em;
  .sidebar-header {
    background: #49b9e7;
    display: flex;
    gap: 1em;
    padding: 0.75rem 1rem;

    img {
      // width: 2em;
      align-self: center;
      justify-self: center;
      height: 32px;
    }

    .sidebar-title {
      color: #fff;
      font-size: 1.2em;
      line-height: 2em;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  ul {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.5em 0;
      border-bottom: 2px solid #065695;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: #000000;
        transition: padding 0.3s;
        font-weight: bold;
        cursor: pointer;

        &.active {
          color: #065695;
        }
        &:hover {
          color: #065695;
          padding-left: 12px;
        }
      }
    }
  }
}

.online-consult {
  padding: 1.5em 2em;
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  color: #fff;
  background: #065695;
  border-radius: 8px;

  img {
    width: 24px;
  }
}

.banner {
  position: relative;
  height: 80vh;

  .banner-image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      border: 0;
      vertical-align: middle;
      width: fit-content;
      height: fit-content;
    }
  }

  .flexbox {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;

    .text-center {
      color: #fff;
      text-align: center;
      // text-transform: uppercase;
      z-index: 5;
    }
  }

  .overlay {
    position: absolute;
    background: #065695;
    top: 0;
    opacity: 0.6;
    width: 100%;
    height: 80vh;
    // z-index: 2;
  }
}

.main-content {
  display: flex;

  @media (max-width: 800px) {
    // padding: 5em 24px;
    flex-direction: column;
  }

  .main {
    // margin: 1rem;
    width: 70%;
    line-height: 1.5rem;
    @media (max-width: 800px) {
      width: 100%;
      margin: 0;
    }

    p {
      margin: 1rem 0;
    }

    h2 {
      font-size: 1rem;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>