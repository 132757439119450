<template>
  <section>
    <div class="nav">
      <div class="container">
        <div class="nav-content">
          <a href="/" class="brand-logo">
            <img src="~@/assets/images/logo-white.png" alt="" />
          </a>
          <ul class="nav-menu">
            <li class="nav-item">
              <router-link tag="a" active-class="active" to="/dosing-pumps"
                >DOSING PUMPS</router-link
              >
            </li>
            <li class="nav-item">
              <router-link tag="a" active-class="active" to="/dosing-packages"
                >DOSING PACKAGES</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                tag="a"
                active-class="active"
                to="/dosing-application"
                >APPLICATION</router-link
              >
            </li>
            <li class="nav-item">
              <router-link tag="a" active-class="active" to="/solution"
                >SOLUTION</router-link
              >
              <!-- <div class="nav-more-list">
              <div class="nav-more-list-item">
                <a href="">Chlorine Dosing Pump</a>
              </div>
            </div> -->
            </li>
            <li class="nav-item">
              <router-link
                tag="a"
                active-class="active"
                to="/dosing-pumps-support/wiki"
                >PUMPS SUPPORT</router-link
              >
            </li>
            <li class="nav-item">
              <router-link tag="a" active-class="active" to="/video"
                >VIDEO</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-boxs">
      <div class="mobile-tops">
        <a href="/" class="logo">
          WATERFLOW
          <!-- <img src="~@/assets/images/logo-white.png" alt="WaterFlow" /> -->
        </a>
        <div id="mobile-nav-box" v-show="menu">
          <ul>
            <li>
              <a href="/dosing-pumps">DOSING PUMPS</a>
            </li>
            <li>
              <a href="/dosing-packages">DOSING PACKAGES</a>
            </li>
            <li>
              <a href="/dosing-application">APPLICATION</a>
            </li>
            <li>
              <a href="/solution">SOLUTION</a>
            </li>
            <li>
              <a href="/dosing-pumps-support/wiki">PUMPS SUPPORT</a>
            </li>
            <li>
              <a href="/video">VIDEO</a>
            </li>
            <li>
              <a href="/company/profile">COMPANY</a>
            </li>
            <li>
              <a href="/contact">CONTACT US</a>
            </li>
          </ul>
        </div>
        <a class="icon" v-on:click="menu = !menu">
          <i class="fa fa-bars"></i>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$color1: #065695;
$color2: #49b9e7;
$color3: #928989;

.mobile-boxs {
  display: none;
}
.nav {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  position: sticky;
  background: $color1;
  color: white;
  // min-height: 52px;
}

.container {
  width: 100%;
  padding: 0 3rem;
}

.nav-content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 24px;
  background: $color1;
  // top: 100%;
  z-index: 999;
  // position: relative;
  zoom: 1;

  .brand-logo {
    cursor: pointer;
    align-self: left;
    display: inline-flex;
    align-items: center;
    padding: 24px;

    img {
      height: 42px;

      @media (max-width: 768px) {
        // padding: 8px 0;
      }
    }
  }

  .nav-menu {
    float: right;
    overflow: scroll;
    // white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    // max-width: 1500px; /* combined width of all LI's */
    // height: 86px;

    &:before {
      content: "";
      display: table;
      clear: both;
    }

    @media (max-width: 768px) {
      display: none;
    }

    .nav-item {
      float: left;
      padding: 0 16px;
      position: relative;

      a {
        font-size: 15px;
        color: #fff;
        display: inline-block;
        padding: 40px 0;
        position: relative;
        transition: all 0.5s;
        text-transform: uppercase;

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          left: 0;
          right: 100%;
          bottom: -2px;
          // background: #368e65;
          height: 2px;
          -webkit-transition-property: right;
          transition-property: right;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }

        &:hover {
          position: static;
        }

        &:hover::before,
        &:focus::before,
        &:active::before {
          right: 0;
        }

        &:hover,
        &:focus,
        &.active,
        &:active {
          border-bottom: 3px solid $color2;
        }
      }

      .nav-more-list {
        position: absolute;
        top: 100%;
        left: -10px;
        background: #fff;
        z-index: 999;
        box-shadow: 0 0 4px #ddd;
        min-width: 230px;
        // visibility: hidden;
        background: white;
        padding: 0;

        .nav-more-list-item {
          a {
            display: block;
            margin: 0;
            padding: 12px;
            color: black;
            background: transparent;

            &:hover {
              color: white;
              background: #065695;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav {
    display: none;
  }

  .mobile-boxs {
    display: block;
    max-width: 768px;
    // margin: auto;
    z-index: 12;
    left: 0;
    right: 0;
    color: white;
    position: fixed;
    .mobile-tops {
      overflow: hidden;
      background-color: #065695;
      position: relative;

      a {
        color: white;
        padding: 24px 16px;
        text-decoration: none;
        font-size: 17px;
        display: block;

        &.icon {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    #mobile-nav-box {
      background: #ffffff;

      ul {
        padding: 1em;
        li {
          a {
            font-size: 0.9rem;
            display: block;
            color: #065695;
            padding: 0.75em 0;
            border-bottom: 1px solid #065695;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
