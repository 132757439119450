<template>
	<transition name="fade">
		<div v-if="show" class="preloader">
			<!-- <div class="logo"></div> -->
			<div class="dot-pulse"></div>
			<!-- <p>Loading</p>
			<div class="circles">
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
			</div> -->
		</div>
	</transition>
</template>


<script>
export default {
	name: 'loading',
	data(){
		return {
			show: true
		}
	},
	mounted(){
		if(Boolean(this.show)) this.showToggle()
	},
	methods: {
		showToggle(){
			setTimeout(() => {
				this.show = false
			}, 700)
		}
	}
}
</script>

<style lang="scss" scoped>

.preloader {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	justify-content: center;
	align-items: center;
	background: rgba($color: #7486ee, $alpha: 1);
}

// .preloader{
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	background-color: #fff;
// 	z-index: 9999;
// 	.logo {

// 		width: 12.5rem;
// 		height: 12.5rem;
// 		background: url("~@/assets/images/logo-white.png");
// 		background-repeat: no-repeat;
// 	}
// 	p {

// 		// font: 600 1.5rem $font2;
// 		margin-top: 1rem;
// 	}
// 	.circles {

// 		display: flex;
// 		margin-top: 1rem;
// 		.circle {

// 			width: 1.5rem;
// 			height: 1.5rem;
// 			margin: .75rem;
// 			background-color: blue;
// 			border-radius: 50%;
// 			animation-name: scaleIn;
// 			animation-duration: .7s;
// 			transform: scale(0);
// 			&:nth-child(1) {
// 				animation-delay: 0s;
// 			}
// 			&:nth-child(2){
// 				animation-delay: .05s;
// 			}
// 			&:nth-child(3){
// 				animation-delay: .1s;
// 			}
// 			&:nth-child(4){
// 				animation-delay: .15s;
// 			}
// 			&:nth-child(5){
// 				animation-delay: .2s;
// 			}
// 		}
// 	}
// }
/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px #ffffff;
  animation: dotPulse 1.5s infinite linear;
  z-index: 9999;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffffff;
  }
}
</style>