<template>
  <section>
    <loading/>
    <div class="application">
      <div class="banner">
        <div class="banner-image">
          <img
            src="~@/assets/images/dosing-top.png"
            alt="Diaphragm Dosing Metering Pump Solutions"
          />
        </div>
        <div class="overlay"></div>
        <div class="flexbox">
          <h1 id="banner-title" class="text-center">VIDEO</h1>
        </div>
      </div>

      <div class="container main-content">
        <div class="main">
          <div class="sep-resource-grid">
            <ul class="sep-datalist">
              <li>
                <a
                  href="/video/disassembly-and-installation-of-check-valves.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200812/08/disassembly-and-installation-of-check-valces_400x400.jpg"
                    alt="Disassembly and Installation of Check Valves" /></a
                ><a
                  href="/video/disassembly-and-installation-of-check-valves.html"
                  class="title"
                  >Disassembly and Installation of Check Valves</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/disassembly-and-installation-of-check-valves.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/electrical-wiring-of-solenoid-metering-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/electrical-wiring-of-solenoid-pump_400x400.jpg"
                    alt="Electrical Wiring of Solenoid Metering Pump" /></a
                ><a
                  href="/video/electrical-wiring-of-solenoid-metering-pump.html"
                  class="title"
                  >Electrical Wiring of Solenoid Metering Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/electrical-wiring-of-solenoid-metering-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/flow-calibration-of-solenoid-metering-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/flow-calibration-of-solenoid-pump_400x400.jpg"
                    alt="Flow Calibration of Solenoid Metering Pump" /></a
                ><a
                  href="/video/flow-calibration-of-solenoid-metering-pump.html"
                  class="title"
                  >Flow Calibration of Solenoid Metering Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/flow-calibration-of-solenoid-metering-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/installation-of-solenoid-metering-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/installation-of-solenoid-pump_1597139718_400x400.jpg"
                    alt="Installation of Solenoid Metering Pump" /></a
                ><a
                  href="/video/installation-of-solenoid-metering-pump.html"
                  class="title"
                  >Installation of Solenoid Metering Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/installation-of-solenoid-metering-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/the-common-troubleshooting-of-solenoid-metering-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/the-commeon-troubleshouting-of-solenoid-pump_400x400.jpg"
                    alt="The Common Troubleshooting of Solenoid Metering Pump" /></a
                ><a
                  href="/video/the-common-troubleshooting-of-solenoid-metering-pump.html"
                  class="title"
                  >The Common Troubleshooting of Solenoid Metering Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/the-common-troubleshooting-of-solenoid-metering-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/the-commissioning-of-dm-series-solenoid-dosing-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/the-commissioning-of-dm-series-pump_400x400.jpg"
                    alt="The Commissioning of Dm Series Solenoid Dosing Pump" /></a
                ><a
                  href="/video/the-commissioning-of-dm-series-solenoid-dosing-pump.html"
                  class="title"
                  >The Commissioning of Dm Series Solenoid Dosing Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/the-commissioning-of-dm-series-solenoid-dosing-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/the-commissioning-of-dp-series-solenoid-dosing-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/the-commissioning-of-dp-series-pump_1597139812_400x400.jpg"
                    alt="The Commissioning of Dp Series Solenoid Dosing Pump" /></a
                ><a
                  href="/video/the-commissioning-of-dp-series-solenoid-dosing-pump.html"
                  class="title"
                  >The Commissioning of Dp Series Solenoid Dosing Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/the-commissioning-of-dp-series-solenoid-dosing-pump.html"
                  class="btn-read"
                ></a>
              </li>
              <li>
                <a
                  href="/video/the-way-of-hard-tube-connecting-with-solenoid-metering-pump.html"
                  class="thumb"
                  ><img
                    src="/uploads/image/20200811/17/the-way-of-hard-tube-connecting-with-solenoid-pump_400x400.jpg"
                    alt="The Way of Hard Tube Connecting with Solenoid Metering Pump" /></a
                ><a
                  href="/video/the-way-of-hard-tube-connecting-with-solenoid-metering-pump.html"
                  class="title"
                  >The Way of Hard Tube Connecting with Solenoid Metering
                  Pump</a
                ><span class="published">2020/08/11</span
                ><span class="summary"></span
                ><a
                  href="/video/the-way-of-hard-tube-connecting-with-solenoid-metering-pump.html"
                  class="btn-read"
                ></a>
              </li>
            </ul>
            <div class="sep-pagination">
              <ul class="pagination">
                <li><a href="?" rel="prev" class="prev-page disable">«</a></li>
                <li></li>
                <li class="active"><a href="?">1</a></li>
                <li></li>
                <li><a href="?" rel="next" class="next-page disable">»</a></li>
              </ul>
            </div>
          </div>
        </div>
      <div class="side-bar">
        <div class="online-consult">
          <img
            src="~@/assets/images/consult-icon.png"
            alt="Online Consultation"
          />
          <p>Online Consultation</p>
        </div>
      </div>
      </div>

    </div>
  </section>
</template>
<script>
import Loading from '../components/loading.vue'
export default {
  title: "Video",
  components: { Loading}
}
</script>

