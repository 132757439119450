<template>
  <section>
    <loading />
    <div class="application">
      <div class="banner">
        <div class="banner-image">
          <img
            src="~@/assets/images/dosing-top.png"
            alt="Diaphragm Dosing Metering Pump Solutions"
          />
        </div>
        <div class="overlay"></div>
        <div class="flexbox">
          <h1  id="banner-title" class="text-center">Diaphragm Dosing Metering Pump Solutions</h1>
          <!-- <h1 class="text-center"></h1> -->
        </div>
      </div>

      <div class="container main-content">
        <div class="main">
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </div>
        <div class="side-bar">
          <div class="dosing-sidebar">
            <p class="sidebar-header" style="padding-left: 20px">
              <a class="sidebar-title" href="/">SOLUTION</a>
            </p>
            <ul class="">
              <li>
                <router-link
                  tag="a"
                  active-class="active"
                  to="/solution/chlorine"
                  >Chlorine Dosing Pump</router-link
                >
              </li>
              <li>
                <router-link tag="a" active-class="active" to="/solution/epoxy"
                  >Epoxy Metering Pumps</router-link
                >
              </li>
              <li>
                <router-link
                  tag="a"
                  active-class="active"
                  to="/solution/saltwater"
                  >Saltwater Dosing Pump</router-link
                >
              </li>
              <li>
                <router-link
                  tag="a"
                  active-class="active"
                  to="/solution/sulfuric"
                  >Sulfuric Acid Metering Pump</router-link
                >
              </li>
              <li>
                <router-link
                  tag="a"
                  active-class="active"
                  to="/solution/microchemical"
                  >Micro Chemical Dosing Pump</router-link
                >
              </li>
              <li>
                <router-link
                  tag="a"
                  active-class="active"
                  to="/solution/desalination"
                  >Desalination Metering Pump</router-link
                >
              </li>
            </ul>
          </div>
          <div class="online-consult">
            <img
              src="~@/assets/images/consult-icon.png"
              alt="Online Consultation"
            />
            <p>Online Consultation</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "../components/loading.vue";

export default {
  name: "Solution",

  components: {
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.app-card {
  margin: 12px 0;
  display: flex;
  gap: 1em;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .card-image {
    // height: 18rem;
    flex: 1 0 0;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .card-texts {
    flex: 1 0 0;
    padding: 0.5em;
    h3 {
      font-weight: 300;
      display: inline-block;
      padding-bottom: 5px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        bottom: 0;
        border-bottom: 3px solid #065695;
      }
    }
  }
}
</style>