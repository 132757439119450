<template>
<section>
  <loading/>
  <div class="home">
    <div class="home-banner">
      <div class="banner">
        <div class="texts">
          <h1 class="heading">WaterGate</h1>
          <div class="subheading">
            <p>Integrated Metering System</p>
          </div>

          <div class="subheading-lists">
            <p>
              <img class="check" src="~@/assets/images/check.png" alt="" />
              <span>
                Easy and Quick to watch the liquid level in real time to adjust
                and calibrate
              </span>
            </p>
            <p>
              <img class="check" src="~@/assets/images/check.png" alt="" />
              <span>
                Collect the mixing, calibrating and storing in one tank system
              </span>
            </p>
            <p>
              <img class="check" src="~@/assets/images/check.png" alt="" />
              <span> Much safer and save more space </span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="banner-container">
        <img src="~@/assets/images/banner.png" alt="" />
      </div> -->
    </div>
    <div class="second-container">
      <div class="object">
        <img
          src="~@/assets/images/dosing-sidebar-top-image.png"
          alt="dosing-sidebar-top-image"
        />
      </div>
      <div class="right-texts">
        <h2>Diaphragm Dosing Metering Pump Manufacturer</h2>
        <p>
          There is a wide range of high-efficiency and accurate diaphragm
          metering pumps for most requirements for dosing system. Newdose is
          founded in 2001, tring to solve the users' demand according to the
          installation situation and technical data. It's typical single
          diaphragm metering pump, have solenoid driven type and motor-driven
          type. We'll help to choose the right one for your need.
        </p>
      </div>
    </div>
    <div class="container">
      <div class="home-cards">
        <div class="card">
          <div class="card-image">
            <img src="~@/assets/images/solenoid-dosing-pump.png" alt="" />
          </div>
          <h3>D Type Electromagnetic Solenoid Dosing Pump</h3>
          <ul>
            <li>Capacity range: 1-55 L/h</li>
            <li>Pressure: 0-20Bar</li>
            <li>Accuracy rate: ±1% range</li>
          </ul>
        </div>
        <div class="card">
          <div class="card-image">
            <img src="~@/assets/images/solenoid-dosing-pump.png" alt="" />
          </div>
          <h3>D Type Electromagnetic Solenoid Dosing Pump</h3>
          <ul>
            <li>Capacity range: 1-55 L/h</li>
            <li>Pressure: 0-20Bar</li>
            <li>Accuracy rate: ±1% range</li>
          </ul>
        </div>
        <div class="card">
          <div class="card-image">
            <img src="~@/assets/images/solenoid-dosing-pump.png" alt="" />
          </div>
          <h3>D Type Electromagnetic Solenoid Dosing Pump</h3>
          <ul>
            <li>Capacity range: 1-55 L/h</li>
            <li>Pressure: 0-20Bar</li>
            <li>Accuracy rate: ±1% range</li>
          </ul>
        </div>
        <div class="card">
          <div class="card-image">
            <img src="~@/assets/images/solenoid-dosing-pump.png" alt="" />
          </div>
          <h3>D Type Electromagnetic Solenoid Dosing Pump</h3>
          <ul>
            <li>Capacity range: 1-55 L/h</li>
            <li>Pressure: 0-20Bar</li>
            <li>Accuracy rate: ±1% range</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="solenoid-container">
        <p>
          <img src="~@/assets/images/Star.png" alt="" style="height: 18px" />
          LOOKING FOR THESE DIAPHRAGM DOSING METERING PUMPS?
        </p>
        <div class="solenoids">
          <div class="solenoids-item"><router-link to="/dosing-pumps/solenoid-pump">Solenoid Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/metering-pump">Metering Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/diaphragm-pump">Diagphram Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/solenoid-pump">Solenoid Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/metering-pump">Metering Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/diaphragm-pump">Diagphram Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/motor-driven-diaphragm-pump">Motor Driven Diagphram Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/low-pressure-dosing-pump">Low Pressure Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/high-pressure-dosing-pump">High Pressure Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/solenoid-pump">Solenoid Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/diaphragm-pump">Diagphram Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/motor-driven-diaphragm-pump">Motor Driven Diagphram Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/low-pressure-dosing-pump">Low Pressure Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/high-pressure-dosing-pump">High Pressure Dosing Pump</router-link></div>
          <div class="solenoids-item"><router-link to="/dosing-pumps/solenoid-pump">Solenoid Dosing Pump</router-link></div>
         
        </div>
      </div>
    </div>

    <div class="bottom-container">
      <div class="bottom-card">
        <div class="img-bg">
          <div class="outer-img">
            <img class="" src="~@/assets/images/pure-ptfe-diaphragm.png" />
          </div>
          <p>PURE PTFE DIAPHRAGM 5 YEARS WARRANTY</p>
        </div>
        <div class="bottom-card-texts">
          <ul>
            <li>High pressure maximum life to 3 Mpa</li>
            <li>Excellent diaphragm assembley lift the head greatly</li>
            <li>High toughness</li>
          </ul>
        </div>
      </div>
      <div class="bottom-card">
        <div class="img-bg">
          <div class="outer-img">
            <img class="" src="~@/assets/images/pure-ptfe-valva-seat.png" />
          </div>
          <p>PURE PTFE VALVA SEAT</p>
        </div>
        <div class="bottom-card-texts">
          <ul>
            <li>High performance</li>
            <li>High intensitive corrosion resistance</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import loading from '../components/loading.vue';
export default {
  name: "Home",
  title: "Home",
  components: { loading },
};
</script>

<style lang="scss" scoped>
$color1: #065695;
$color2: #49b9e7;
$color3: #f1f1f1;

.home {
  background: $color3;
}

.home-banner {
  background: linear-gradient(to right, $color1, $color2);
  color: #f1f1f1;
  padding: 54px 3em 2em 3em;
  min-height: 80vh;
  // z-index: 1;

  @media (max-width: 768px) {
      height: fit-content;
      padding: 54px 1.5em 2em 1.5em;
    }


  .banner {
    right: 24px;
    background-image: url("~@/assets/images/banner.png");
    background-repeat: no-repeat;
    background-size: 300px;
    background-position: right;
    height: fit-content;
    @media (max-width: 768px) {
      background: transparent;
      right: 0;
    }
  }

  .texts {
    background: transparent;
    @media (max-width: 800px) {
      width: 100%;
    }
    .heading {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 3.5em;
      line-height: 2em;
      font-weight: bold;
      text-shadow: 0 3px 10px #000;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 2.5em;
      }
    }
    .subheading {
      font-family: "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial,
        sans-serif;
      font-size: 3em;
      font-weight: bold;
      margin-bottom: 1rem;
      text-shadow: 0 3px 10px #000;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 1.5em;
      }
    }

    .subheading-lists {
      // line-height: 2em;

      p {
        margin: 1em 0;
        display: flex;
        text-shadow: 0 3px 5px #000;

        .check {
          align-self: center;
          height: 1.5em;
        }

        span {
          display: inline-block;
          margin-left: 12px;
          font-size: 1.5em;
          line-height: 1.5em;

          @media (max-width: 768px) {
            font-size: 1.25em;
          }
        }
      }
    }
  }
}

.second-container {
  position: relative;
  top: -2.5rem;
  overflow: hidden;
  border-radius: 12px;
  margin: 0 3em;
  color: $color3;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 12px;
    margin: 0 24px;
  }

  .object {
    flex: 25%;
    background: $color2;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 3em;

    img {
      height: 52px;
    }
  }

  .right-texts {
    flex: 75%;
    background: $color1;
    padding: 3rem 2rem;
    line-height: 2em;

    @media (max-width: 800px) {
      flex-direction: column;
      padding: 2rem 1.5rem;
    }

    h2 {
      text-align: center;
      margin: 1em 0;
    }
    p {
      font-size: 1em;
    }
  }
}

.solenoid-container {
  p {
    text-align: center;
    margin: 3em;
    color: $color1;
    font-weight: bold;
  }
  .solenoids {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-around;

    .solenoids-item {
      padding: 8px 16px;
      border: 1px solid black;
      border-radius: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.home-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  @media (max-width: 800px) {
    flex-direction: column;
  }

  .card {
    flex: 45%;
    background: #ffffff;
    border-radius: 8px;
    padding: 2em;
    color: $color1;
    line-height: 1.5em;
    .card-image {
      display: flex;
      justify-content: center;

      img {
        width: 60vh;
        max-width: 100%;
        align-self: center;
      }
    }

    h3 {
      font-weight: bold;
      margin: 1em 0;
    }

    ul {
      li {
        list-style-type: disc;
        list-style-position: inside;
      }
    }
  }
}

.bottom-container {
  display: flex;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .bottom-card {
    position: relative;
    // flex: 1 0 0;

    width: 100%;
    height: 60vh;
    min-height: 450px;
    background: url("~@/assets/images/unsplash_first.png");
    background-size: cover;

    &:nth-child(2) {
      background: url("~@/assets/images/unsplash.png");
    }

    .img-bg {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: auto;
      // min-width: none;
      max-width: 500px;
      padding: 1em;
      justify-content: center;

      .outer-img {
        width: 50%;

        min-height: 200px;
        display: flex;
        align-self: center;
        justify-items: center;
        align-items: center;

        img {
          width: 100%;
          align-self: center;
          justify-self: center;
        }
      }

      p {
        margin: 1em 0;
        text-align: center;
        font-size: 1.5em;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .bottom-card-texts {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 2em;
      min-height: 130px;

      ul {
        font-size: 0.9em;
        line-height: 1.5em;
        color: #065695;
        font-weight: bold;

        li {
          list-style-type: disc;
          list-style-position: inside;
        }
      }
    }
  }
}

.container {
  margin: 1em 3rem;

  @media (max-width: 800px) {
    margin: 1em 24px;
  }
}
</style>