<template>
  <section>
    <loading />
    <div class="application">
      <div class="banner">
        <div class="banner-image">
          <img
            src="~@/assets/images/dosing-top.png"
            alt="Diaphragm Dosing Metering Pump Solutions"
          />
        </div>
        <div class="overlay"></div>
        <div class="flexbox">
          <h1 id="banner-title" class="text-center">Contact Us</h1>
          <!-- <h1 class="text-center"></h1> -->
        </div>
      </div>

      <div class="container main-content">
        <div class="main">
          <div class="contact-cards">
            <ul>
              <li>
                <div class="contact-card">
                  <i
                    class="fa fa-mobile"
                    aria-hidden="true"
                    style="font-size: 48px"
                  ></i
                  ><br />
                  <strong>Tel: </strong>86-17600697421<br />
                </div>
              </li>
              <li>
                <div class="contact-card">
                  <a
                    href="mailto:info@watergateflow.co.za<br>projects@watergateflow.co.za"
                    style="text-decoration: none"
                  >
                    <i class="fa fa-envelope"></i><br />
                    <strong>E-mail: </strong
                    >info@watergateflow.co.za<br />projects@watergateflow.co.za<br />
                  </a>
                </div>
              </li>

              <li>
                <div class="contact-card">
                  <i class="fa fa-map" aria-hidden="true"></i><br />
                  <strong>Address:</strong> Block A-903, CATIC Plaza, No.15
                  Ronghua South Road, Daxing District, Beijing, China.
                  (100176)<br />
                </div>
              </li>
            </ul>
          </div>
          <div class="form-box">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="group">
                <input
                  name="user_name"
                  placeholder="Name"
                  type="text"
                  autocomplete="0"
                />
                <input
                  name="user_email"
                  placeholder="Email"
                  type="email"
                  autocomplete="0"
                />
              </div>
              <div class="group">
                <input
                  name="user_phone"
                  placeholder="Phone"
                  type="text"
                  autocomplete="0"
                />
                <input
                  name="user_company"
                  placeholder="Company"
                  type="text"
                  autocomplete="0"
                />
              </div>
              <textarea
                name="message"
                placeholder="Content"
                rows="7"
              ></textarea>
              <div class="center">
                <button type="submit" value="Send">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <div class="side-bar">
          <div class="online-consult">
            <img
              src="~@/assets/images/consult-icon.png"
              alt="Online Consultation"
            />
            <p>Online Consultation</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import loading from "../components/loading.vue";
import emailjs from "emailjs-com";

export default {
  title: "Contact Us",
  components: { loading },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_qiupcs8",
          "template_7lqye1a",
          this.$refs.form,
          "user_LB00mZvUgp55lQVUvlZXN"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.$router.push("/success-submission");
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-cards {
  font-size: 1em;
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    li {
      flex: calc((100% / 3) - 2em);

      @media only screen and (max-width: 639px) {
        flex: 100%;
      }

      @media only screen and (max-width: 959px) {
        flex: calc(50%- 1em);
      }

      .contact-card {
        height: 100%;
        width: 100%;
        background: #f5f8f9;
        padding: 1em;
        border-radius: 8px;
        text-align: center;

        i {
          font-family: "FontAwesome";
          display: block;
          width: 65px;
          height: 65px;
          margin: 0 auto;
          font-size: 30px;
          line-height: 65px;
          background: #666;
          color: #fff;
          border-radius: 50%;
          position: relative;

          &:hover {
            color: #49b9e7;
          }
        }
      }
      a {
        color: inherit;

        &:hover {
          color: #49b9e7;
        }
      }
    }
  }
}

.form-box {
  margin: 1em 0;
  background: #f5f8f9;
  padding: 24px;
  border-radius: 8px;

  form {
    width: 100%;

    .group {
      display: flex;
      gap: 0.75em;
      margin: 0.75em 0;
      @media only screen and (max-width: 639px) {
        flex-direction: column;
      }
    }

    input,
    textarea {
      width: 100%;
      outline: none;
      padding: 12px;
      border-radius: 4px;
      resize: none;
      border: 2px solid #065695;
    }

    .center {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    button {
      // align-self: center;
      margin: 0.5em auto;
      color: #fff;
      padding: 8px 50px;
      background: #065695;
      border: 2px solid #065695;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #065695;
        background: #fff;
      }
    }
  }
}
</style>