<template>
<section>
  <loading/>
  <div class="dosing">
    <div class="banner">
      <div class="banner-image">
        <img
          src="~@/assets/images/unsplash_2.png"
          alt="Dosing Pump Manufacturer"
        />
      </div>
      <div class="overlay"></div>
      <div class="flexbox">
        <h1 id="banner-title" class="text-center">DOSING PUMPS</h1>
        <!-- <h1 class="text-center">Dosing Pump Manufacturer</h1> -->
      </div>
    </div>

    <div class="container main-content">
      <div class="main">
        <transition name="fade">
        <router-view></router-view>
        </transition>
      </div>
      <div class="side-bar">
        <div class="dosing-sidebar">
          <div class="sidebar-header">
            <img
              src="~@/assets/images/dosing-sidebar-top-image.png"
              alt="dosing-sidebar-top-image"
            />
            <h2 class="sidebar-title">DOSING PUMPS</h2>
          </div>
          <ul>
            <li><router-link active-class="active" to="/dosing-pumps/solenoid-pump">Solenoid Dosing Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/motor-driven-diaphragm-pump">Motor Driven Diaphragm Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/manual-dosing-pump">Manual Dosing Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/metering-pump">Metering Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/diaphragm-pump">Diaphragm Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/high-pressure-dosing-pump">High Pressure Dosing Pump</router-link></li>
            <li><router-link active-class="active" to="/dosing-pumps/low-pressure-dosing-pump">Low Pressure Dosing Pump</router-link></li>
          </ul>
        </div>

        <div class="online-consult">
          <img
            src="~@/assets/images/consult-icon.png"
            alt="Online Consultation"
          />
          <p>Online Consultation</p>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import Loading from '../components/loading.vue';

export default {
  name: "DosingPumps",
  components : {
    Loading
  },
};
</script>

<style lang="scss" scoped>
$color1: #065695;
$color2: #49b9e7;
$color3: #f1f1f1;

.dosing {
  background: #fff;
}


.dosing-content {
  display: flex;

  @media (max-width: 800px) {
    // padding: 5em 24px;Solenoid Dosing Pump
    flex-direction: column;
  }

  .dosing-main {
    margin: 1rem;
    width: 65%;
    line-height: 1.5rem;
    @media (max-width: 800px) {
      width: 100%;
      margin: 0;
    }

    p {
      margin: 1rem 0;
    }

    h2 {
      font-size: 1rem;
    }
  }

  .dosing-side {
    width: 35%;
    padding: 1em;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.image-card {
  margin: 2em 0;
  img {
    max-width: 100%;
    max-height: 250px;
    //  object-fit: cover;
  }
}

.online-consult {
  padding: 1.5em 2em;
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  color: #fff;
  background: #065695;
  border-radius: 8px;

  img {
    width: 24px;
  }
}

.container {
  margin: 1em 3rem;

  @media (max-width: 800px) {
    margin: 1em 24px;
  }
}
</style>